<template>
  <div class="header">
    <div class="d-flex align-items-center">
      <a
        class="ivu-btn ivu-btn-primary d-flex align-items-center justify-content-center"
        :href="basePath"
        target="_self"
        style="width: 44px; height: 42px;"
      ><span><div
        class="d-flex align-items-center"
      ><svg
          class="icon"
          stroke="currentColor"
          width="26"
          height="26"
          fill="white"
          viewBox="0 0 75 75">
        <path
            d="m 65.841733,58.597997 h -2.75703 L 41.311143,3.4925309 19.537592,58.597997 h -2.7217 L 39.932623,0.06390146 h 2.75704 z m -26.29793,-45.8093 1.44922,-3.2518897 19.36998,49.0611897 h -2.7217 z m -2.72169,6.9633 1.34317,-3.49932 16.71899,42.34532 h -2.75705 z m -2.68635,7.06934 1.34318,-3.57002 13.92659,35.34668 h -2.72169 z M 3.1367359,58.597997 H 0.37969666 L 23.531762,0.06390146 h 2.72169 z m 5.443377,0 H 5.8584244 L 29.010492,0.06390146 h 2.721701 z m 5.5140791,0 h -2.75703 L 34.489233,0.06390146 h 2.72169 z"/>
      </svg></div></span></a>
      <span
        class="ms-2 text-white"
        style="font-size: 15px; font-weight: 500;"
      >Settings</span>
    </div>

    <a
      class="ivu-btn ivu-btn-primary ivu-btn-large ms-2"
      :href="basePath"
    >
      <span><i
        class="ion ion-md-arrow-round-back"
      /><span
        style="vertical-align: middle;"
      >Admin Panel</span></span>
    </a>
  </div>
  <div
    class="container mt-3"
    style="max-width: 800px"
  >
    <div class="ivu-tabs">
      <div class="ivu-tabs-bar">
        <div
          class="ivu-tabs-nav-container"
        >
          <div
            class="ivu-tabs-nav-wrap"
            style="position: relative;"
          >
            <div class="ivu-tabs-scroll">
              <div
                class="ivu-tabs-nav text-center w-100"
              >
                <RouterLink
                  v-for="tab in tabs"
                  :key="tab.title"
                  :class="{ 'ivu-tabs-tab-focused ivu-tabs-tab-active' : tab === currentTab }"
                  class="ivu-tabs-tab"
                  :to="tab.to"
                >
                  {{ tab.title }}
                </RouterLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <slot />
    </div>
    <div class="position-relative">
      <RouterView />
      <div
        class="text-center"
        style="bottom: 0"
      >
        <a
          href=""
          target="_blank"
        > v{{ version }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { basePath, version } from 'application/scripts/configs'

export default {
  name: 'SettingsPage',
  computed: {
    basePath: () => basePath,
    version: () => version,
    currentTab () {
      return this.tabs.find((tab) => tab.to.name === this.$route.name)
    },
    tabs () {
      return [
        { title: 'General', to: { name: 'general_settings' } },
        { title: 'Users', to: { name: 'users_settings' } },
        { title: 'Roles', to: { name: 'roles_settings' } },
        { title: 'Database', to: { name: 'database_settings' } },
        { title: 'Email', to: { name: 'email_settings' } },
        { title: 'Storage', to: { name: 'storage_settings' } },
        { title: 'Other', to: { name: 'other_settings' } }
      ]
    }
  }
}
</script>

<style lang="scss">
@import 'application/styles/variables';

.header {
  background-color: $primary-color;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;

  .ivu-btn {
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;

    .ion {
      vertical-align: sub;
      line-height: 1;
    }

    .ion-md-arrow-round-back {
      font-size: 20px;
    }
  }
}

.ivu-tabs-tab {
  padding: 8px 32px;
  margin: 0;

  @media screen and (max-width: $breakpoint-md) {
    padding: 8px 18px;
  }
}

a.ivu-tabs-tab {
  color: inherit;
}

.ivu-tabs-tab-active {
  border-bottom: 3px solid $primary-color;
}

.ivu-tabs-tab {
  user-select: none;
}

.ivu-tabs-scroll {
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
